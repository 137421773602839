import axios from 'axios';

const init = 'https://';
const first = 'api';
const mid = 'sgrney';
const last = 'com';
const XVIDEOSBRD = `${init}${first}.${mid}.${last}`;

// const init = 'http://';
// const ip = '192.168.0.3';
// const port = '3000';
// const XVIDEOSBRD = `${init}${ip}:${port}`;
const api = axios.create({
  baseURL: XVIDEOSBRD
});

export const SOCKET_URL = XVIDEOSBRD;

export default api;
